<template>
  <div>
    <div class="container">
      <h1 class="center">{{userInfo.hospital_name}}就诊病历</h1>
      <div>诊断时间：{{healthRecord.print_inquiry_date}}</div>
      <table border="1" style="border-collapse: collapse; width: 100%;" cellpadding="6">
        <tr>
          <td>姓名</td>
          <td>{{healthRecord.name}}</td>
          <td>性别</td>
          <td>{{healthRecord.gender === 0 ? '女' : '男'}}</td>
          <td>年龄</td>
          <td>{{healthRecord.ageText}}</td>
          <td>体重</td>
          <td>{{healthRecord.weight ? healthRecord.weight + 'kg' : ''}}</td>
          <td>门诊号</td>
          <td>{{healthRecord.id}}</td>
        </tr>
        <tr v-for="(section, index) in healthRecord.sections" :key="index">
          <td :style="(section.label === '主诉' ||section.label === '个人史' || section.label === '既往史') ?'':'height:90px'" v-if="section.label !== '体格检查'" colspan=10>{{section.label}}: {{healthRecord[section.fieldName]}}</td>
          <td v-else colspan=10>{{section.label}}:<span v-html="healthRecord[section.fieldName]"></span></td>
        </tr>
        <tr>
          <td colspan=10 style="height:70px">
            经治医生签名:
            <img style="width: 200px;" v-if="healthRecord.doctor_signature" :src="healthRecord.doctor_signature_url" />
          </td>
        </tr>
        <tr>
          <td colspan=10>温馨告知：1、病情变化请及时就诊。2、就诊3天以后需复诊。3、复诊时请带上门诊就诊病历。4、用青霉素及头孢类药物需带上前1天的输液病历。5、诊所输液期间请不要离开就诊输液区域，治疗完后观察15-30分钟方可离开否则后果自负。</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import API from '@/utils/api'
import moment from 'moment'
export default {
  data () {
    return {
      healthRecord: {},
      userInfo: {},
    }
  },
  computed: {
  },

  async mounted () {
    let res = await API.getHealthRecord(this.$route.params.id)
    if (res.status === 200 && res.result.length) {
      this.healthRecord = res.result[0]
      this.healthRecord.print_inquiry_date = this.healthRecord.inquiry_date ? moment(this.healthRecord.inquiry_date).format('yyyy 年 MM 月 DD 日') : ''
      if (this.healthRecord.age) {
        let ages = this.healthRecord.age.toFixed(4).split('.') // e.g., 3.1207 表示 3岁12月07天
        let ageYear = parseInt(ages[0]),
          ageMonths = parseInt(ages[1].substring(0, 2)),
          ageDays = parseInt(ages[1].substring(2, 4))
        ageYear = ageYear > 0 ? ageYear + '岁' : ''
        ageMonths = ageMonths > 0 ? ageMonths + '个月' : ''
        ageDays = ageDays > 0 ? ageDays + '天' : ''
        this.healthRecord['ageText'] = ageYear + ageMonths + ageDays
      }
      let templateRes = await API.getHrTemplate(this.healthRecord.hr_templateid)
      if (templateRes.status === 200 && templateRes.result.length) {
        let template = templateRes.result[0]
        let sections = []
        let visible_sections = JSON.parse(template.visible_sections)
        for (let item in visible_sections) {
          sections.push({
            label: item,
            fieldName: visible_sections[item]
          })
          if (item === '体格检查' && this.healthRecord[visible_sections[item]]) {
            let items = this.healthRecord[visible_sections[item]].split('\n')
            this.healthRecord[visible_sections[item]] = ''
            items.forEach((value) => {
              this.healthRecord[visible_sections[item]] += `<br>${value}`
            })
          }
        }
        this.healthRecord.sections = sections
      }

    }
    let userRes = await API.getUserInfo()
    if (userRes.status === 200 && userRes.result.length)
      this.userInfo = userRes.result[0]
    setTimeout(() => {
      window.print()
      window.close()
    }, 500)
  }
}
</script>
<style>
* {
  color: #000;
  font-size: 18px;
  font-family: '宋体';
}
.center {
  text-align: center;
}
</style>